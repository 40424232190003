import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import Welcome from "../components/layout/welcome";
import Calculator from "../pages/calculator";
import Colors from "../pages/colors";
import Countries from "../pages/countries";
import DigitalServicePlan from "../pages/digitalServicePlan";
import Home from "../pages/home";
import Login from "../pages/login";
import Manual from "../pages/manual";
import ManualInstructions from "../pages/manualInstructions";
import AddVehicleManual from "../pages/manualInstructions/addVehicleManual";
import MarketingCloud from "../pages/marketingCloud";
import Models from "../pages/models";
import Modules from "../pages/modules";
import Onboard from "../pages/onboard";
import OwnersManual from "../pages/ownersManual";
import VersionsList from "../pages/ownersManual/versions-list";
import Paths from "../pages/paths";
import Pills from "../pages/pillsContent";
import PillsOrdering from "../pages/pillsOrdering";
import Profiles from "../pages/profiles";
import Support from "../pages/support";
import Terms from "../pages/terms";
import Users from "../pages/users";
import TransformJson from "../pages/transformJson";
import SendPush from "../pages/sendPush";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="onboard" element={<Onboard />} />
          <Route path="users" element={<Users />} />
          <Route path="countries" element={<Countries />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="paths" element={<Paths />} />
          <Route path="support" element={<Support />} />
          <Route path="manual" element={<Manual />} />
          <Route path="terms" element={<Terms />} />
          <Route path="calculator" element={<Calculator />} />
          <Route path="models" element={<Models />} />
          <Route path="colors" element={<Colors />} />
          <Route path="ownersManual" element={<OwnersManual />} />
          <Route path="ownersManual/versions-list" element={<VersionsList />} />
          <Route path="manualInstructions" element={<ManualInstructions />} />
          <Route path="digitalserviceplan" element={<DigitalServicePlan />} />
          <Route
            path="manualInstructions/addVehicleManual"
            element={<AddVehicleManual />}
          />
          <Route path="marketingcloud" element={<MarketingCloud />} />
          <Route path="pillsContent" element={<Pills />} />
          <Route path="pillsOrdering" element={<PillsOrdering />} />
          <Route path="modules" element={<Modules />} />
          <Route path="transformJson" element={<TransformJson />} />
          <Route path="sendpush" element={<SendPush />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="welcome" element={<Welcome />} />
      </Routes>
    </BrowserRouter>
  );
}
