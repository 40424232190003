import axios from "axios";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import {
  IAuthContextData,
  IAuthProviderProps,
  ISignInCredentials,
} from "../interfaces/IAuthContext";
import { CONFIG } from "../services/config";
import { IUser } from "../interfaces/IUser";
import { refreshToken } from "../utils/vwidService";
import { IHeaders } from "../interfaces/IHeaders";
import useCookies from "../services/CookieService";

export const AuthContext = createContext({} as IAuthContextData);

const { setCookie, findCookie, removeAll } = useCookies();

export function AuthProvider({ children }: IAuthProviderProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>(null as any);
  const [country, setCountry] = useState({
    countryCode: `${findCookie("countryCode")?.value}`,
    country: `${findCookie("countryName")?.value}`,
  });

  const [language, setLanguage] = useState<{
    language: string;
    languageName: string;
  }>({
    language: `${findCookie("language")?.value}`,
    languageName: `${findCookie("languageName")?.value}`,
  });
  const [tokenHeaders, setTokenHeaders] = useState<IHeaders>({
    headers: {
      authorization: `Bearer ${findCookie("accessToken")?.value}`,
      idToken: `${findCookie("idToken")?.value}`,
      code: `${findCookie("code")?.value}`,
      countryCode: country.countryCode,
    },
  });
  const isAuthenticated = !!user;

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        if (
          tokenHeaders.headers.idToken &&
          tokenHeaders.headers.idToken !== "undefined"
        ) {
          const response = await axios.get<IUser>(
            `${CONFIG.API_URL}user/cmsUser`,
            tokenHeaders,
          );
          setUser(response.data);
          setLoading(false);
        }
      } catch {
        setLoading(false);
      }
    }
    load();
  }, [tokenHeaders]);

  useEffect(() => {
    if (user && !findCookie("countryCode")?.value) {
      const { preferedCountry, countries } = user;
      const selectedCountry = countries.find(
        ({ countryCode }) => countryCode === preferedCountry,
      );
      setCountry({
        country: `${selectedCountry?.country}`,
        countryCode: `${selectedCountry?.countryCode}`,
      });
      switch (preferedCountry) {
        case "BR": {
          setCookie({ name: "language", value: "ptbr" });
          setCookie({ name: "languageName", value: "Portuguese" });
          break;
        }
        default: {
          setCookie({ name: "language", value: "en" });
          setCookie({ name: "languageName", value: "English" });
        }
      }
    }
  }, [user]);

  useEffect(() => {
    setCookie({
      name: "countryCode",
      value: `${country.countryCode}`,
    });

    setCookie({ name: "countryName", value: `${country.country}` });
  }, [country]);

  useEffect(() => {
    setCookie({
      name: "language",
      value: ![undefined, "undefined"].includes(language.language)
        ? language.language
        : "en",
    });
    setCookie({
      name: "languageName",
      value: ![undefined, "undefined"].includes(language.languageName)
        ? language.languageName
        : "English",
    });
  }, [language]);

  async function signIn({
    accessToken,
    idToken,
    code,
    expiresIn,
  }: ISignInCredentials) {
    try {
      [
        { name: "accessToken", value: accessToken },
        { name: "idToken", value: idToken },
        { name: "code", value: code },
      ].forEach(({ name, value }) => {
        const fiveMinutes = 5 * 60 * 1000;
        setCookie({ name, value, maxSeconds: Number(expiresIn) - fiveMinutes });
      });

      setCookie({ name: "isLogged", value: "logged" });
      await refreshToken(code);
      if (accessToken && idToken && expiresIn) {
        setTokenHeaders({
          headers: {
            authorization: `Bearer ${accessToken}`,
            idToken,
            code,
            countryCode: country.countryCode,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  function signOut() {
    const idToken = findCookie("idToken");

    if (idToken) {
      const idTokenValue = idToken.value;
      removeAll("language", "languageName", "countryCode", "countryName");
      window.location.href = `${CONFIG.VW_ID_BASEURL}v1/logout?&id_token_hint=${idTokenValue}&post_logout_redirect_uri=${CONFIG.CALLBACK_URL}`;
    } else {
      window.location.href = "/welcome";
    }
  }
  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthenticated,
        user,
        loading,
        country,
        setCountry,
        setLanguage,
        setTokenHeaders,
        language,
        tokenHeaders,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
