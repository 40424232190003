import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import MenuLink from "./MenuLink";
import SelectLink from "./selectLink";

interface IFunctionalities {
  functionalityId: number;
  title: string;
  name: string;
}

export default function Menu() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [openGarageMenu, setOpenGarageMenu] = useState(false);
  const [openPillsMenu, setOpenPillsMenu] = useState(false);
  const [openManualMenu, setOpenManualMenu] = useState(false);

  return (
    <Box
      sx={{
        fontSize: "14px",
        overflowY: "auto",
        height: "100vh",
        flexGrow: 1,
        position: "sticky",
        top: 0,
      }}
    >
      {user && (
        <Box
          sx={{
            backgroundColor: "var(--blue-200)",
            fontSize: "14px",
            overflowY: "auto",
            height: "100vh",
            flexGrow: 1,
            position: "sticky",
            top: 0,
          }}
        >
          <Box>
            <Box>
              <MenuLink title={t("menu.home")} toRoute="home" />
              {user.profile.functionalities
                .sort((a: any, b: any) =>
                  t(`menu.${a.name.toLowerCase()}`).localeCompare(
                    t(`menu.${b.name.toLowerCase()}`),
                  ),
                )
                .map((item: IFunctionalities) => {
                  if (
                    item.name.toLocaleLowerCase() === "models" ||
                    item.name.toLocaleLowerCase() === "colors" ||
                    item.name.toLocaleLowerCase() === "ownersmanual" ||
                    item.name.toLocaleLowerCase() === "pillsordering" ||
                    item.name.toLocaleLowerCase() === "pillscontent" ||
                    item.name.toLocaleLowerCase() === "home"
                  ) {
                    return;
                  }
                  if (item.name.toLocaleLowerCase() === "garage") {
                    return (
                      <React.Fragment key={item.functionalityId}>
                        <SelectLink
                          title={t("menu.garage")}
                          onClick={() => setOpenGarageMenu(!openGarageMenu)}
                          iconChange={openGarageMenu}
                        />
                        {openGarageMenu && (
                          <Box
                            sx={{
                              marginLeft: 3,
                              transition: "1s",
                            }}
                          >
                            <MenuLink
                              title={t("menu.models")}
                              toRoute="models"
                            />
                            <MenuLink
                              title={t("menu.colors")}
                              toRoute="colors"
                            />
                            <MenuLink
                              title={t("menu.ownersmanual")}
                              toRoute="ownersmanual"
                            />
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  }
                  if (item.name.toLocaleLowerCase() === "pills") {
                    return (
                      <React.Fragment key={item.functionalityId}>
                        <SelectLink
                          title={t("menu.pills")}
                          onClick={() => setOpenPillsMenu(!openPillsMenu)}
                          iconChange={openPillsMenu}
                        />
                        {openPillsMenu && (
                          <Box
                            sx={{
                              marginLeft: 3,
                              transition: "1s",
                            }}
                          >
                            <MenuLink
                              title={t("menu.pillsContent")}
                              toRoute="pillsContent"
                            />
                            <MenuLink
                              title={t("menu.pillsordering")}
                              toRoute="pillsordering"
                            />
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  }
                  if (item.name.toLocaleLowerCase() === "manual") {
                    return (
                      <React.Fragment key={item.functionalityId}>
                        <SelectLink
                          title={t("menu.cognitivo")}
                          onClick={() => setOpenManualMenu(!openManualMenu)}
                          iconChange={openManualMenu}
                        />
                        {openManualMenu && (
                          <Box
                            sx={{
                              marginLeft: 3,
                              transition: "1s",
                            }}
                          >
                            <MenuLink
                              title={t("menu.manual")}
                              toRoute="manual"
                            />
                            <MenuLink
                              title={t("menu.transformJson")}
                              toRoute="transformJson"
                            />
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  }
                  return (
                    <MenuLink
                      key={item.title}
                      title={t(`menu.${item.name.toLowerCase()}`)}
                      toRoute={item.name.toLocaleLowerCase()}
                    />
                  );
                })}

              {/* {user.profile.functionalities.map((item: IFunctionalities) => {
                if (item.name.toLocaleLowerCase() !== "garage") {
                  return false;
                }
                return (
                  <>
                    <SelectLink
                      title={t("menu.garage")}
                      onClick={() => setOpenGarageMenu(!openGarageMenu)}
                      iconChange={openGarageMenu}
                    />
                    {openGarageMenu && (
                      <Box
                        sx={{
                          marginLeft: 3,
                          transition: "1s",
                        }}
                      >
                        <MenuLink title={t("menu.models")} toRoute="models" />
                        <MenuLink title={t("menu.colors")} toRoute="colors" />

                        <MenuLink
                          title={t("menu.ownersmanual")}
                          toRoute="ownersmanual"
                        />
                      </Box>
                    )}
                  </>
                );
              })} */}
            </Box>

            {/* <MenuLink title={t("menu.home")} toRoute="home" />
        <MenuLink title={t("menu.onboard")} toRoute="onboard" />
        <MenuLink title={t("menu.countries")} toRoute="countries" />
        <MenuLink title={t("menu.users")} toRoute="users" />
        <MenuLink title={t("menu.profiles")} toRoute="profiles" />
        <MenuLink title={t("menu.paths")} toRoute="paths" />
        <MenuLink title={t("menu.support")} toRoute="support" />
        <MenuLink title={t("menu.manual")} toRoute="manual" />
        <SelectLink
          title={t("menu.garage")}
          onClick={() => setOpenGarageMenu(!openGarageMenu)}
          iconChange={openGarageMenu}
        />
        {openGarageMenu && (
          <Box
            sx={{
              marginLeft: 3,
              transition: "1s",
            }}
          >
            <MenuLink title={t("menu.models")} toRoute="models" />
            <MenuLink title={t("menu.colors")} toRoute="colors" />
            <MenuLink
              title={t("menu.manualInstructions")}
              toRoute="manualInstructions"
            />
            <MenuLink title={t("menu.ownersmanual")} toRoute="ownersManual" />
          </Box>
        )}
        <MenuLink title={t("menu.terms")} toRoute="terms" />
        <MenuLink title={t("menu.calculator")} toRoute="calculator" />
        <MenuLink title={t("menu.pills")} toRoute="pills" />
        <MenuLink title={t("menu.pillsordering")} toRoute="pillsOrdering" /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}
